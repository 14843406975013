import React from 'react';
import '../styles/main.css'; // Importing the main CSS

const teamMembers = [
  { name: "Festus Alpheus", role: "Founder & CEO", description: "Innovative tech leader and visionary with extensive experience in software development and strategic planning. Passionate about leveraging technology to drive business growth and enhance user experiences.", image: "ceo.jpeg" },
  { name: "Michael Amutenya", role: "Network & Cyber", description: "Seasoned expert in network architecture and cybersecurity, dedicated to safeguarding digital assets and optimizing network performance. Proven track record in implementing robust security measures and resolving complex technical challenges.", image: "secure.jpeg" },
  { name: "Petrina Niipele", role: "Marketing Specialist", description: "Strategic marketing professional with a strong background in digital marketing, brand development, and client acquisition. Skilled in crafting compelling marketing campaigns and driving customer engagement to achieve business objectives.", image: "xx.jpeg" }
];

const Team = () => {
  return (
    <section id="team" className="team-container">
      <h2>Meet the Team</h2>
      <div className="team-member-container">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <div className="team-circle">
              <img
                src={`/images/${member.image}`}
                alt={member.name}
                className="team-image"
              />
            </div>
            <h3>{member.name}</h3>
            <p>{member.role}</p>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
