import React, { useState, useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import '../styles/main.css';

const serviceOptions = [
  {
    name: 'Basic Website',
    description: 'Simple 4-page website without database. Perfect for small businesses or personal sites.',
    options: [
      { type: 'Basic', price: 4000.00, details: 'Static HTML/CSS/JAVASCRIPT/PHP' },
      { type: 'Intermediate', price: 7000.00, details: 'Includes SEO and content management' },
      { type: 'Expert', price: 10000.00, details: 'Full features with database integration' },
    ],
  },

  {
    name: 'E-Commerce Website',
    description: 'Online store with product catalog and secure payment integration.',
    options: [
      { type: 'Starter', price: 8000.00, details: 'Up to 50 products' },
      { type: 'Business', price: 15000.00, details: 'Up to 500 products, advanced features' },
      { type: 'Enterprise', price: 25000.00, details: 'Unlimited products, custom features' },
    ],
  },
  {
    name: 'Mobile App Development',
    description: 'Custom mobile application for iOS and/or Android platforms.',
    options: [
      { type: 'Basic', price: 10000.00, details: 'Single platform (iOS or Android)' },
      { type: 'Pro', price: 18000.00, details: 'Both iOS and Android' },
      { type: 'Premium', price: 30000.00, details: 'Advanced features, backend integration' },
    ],
  },
  {
    name: 'SEO Services',
    description: 'Improve your website\'s visibility on search engines.',
    options: [
      { type: 'Basic', price: 500.00, details: 'On-page optimization' },
      { type: 'Standard', price: 1000.00, details: 'On-page and off-page optimization' },
      { type: 'Premium', price: 3000.00, details: 'Comprehensive SEO strategy and execution' },
    ],
  },
  {
    name: 'Content Management System',
    description: 'Easy-to-use system for managing website content without technical skills.',
    options: [
      { type: 'Basic', price: 2000.00, details: 'WordPress or similar platform setup' },
      { type: 'Custom', price: 5000.00, details: 'Tailored CMS development' },
    ],
  },
  {
    name: 'Web Application',
    description: 'Custom web-based software for specific business needs.',
    options: [
      { type: 'Basic', price: 12000.00, details: 'Simple functionality' },
      { type: 'Advanced', price: 25000.00, details: 'Complex features and integrations' },
    ],
  },
  {
    name: 'Database Design',
    description: 'Efficient and scalable database structure for your application.',
    options: [
      { type: 'Small', price: 2500.00, details: 'Up to 10 tables' },
      { type: 'Medium', price: 4500.00, details: 'Up to 30 tables' },
      { type: 'Large', price: 10000.00, details: 'Complex structure, over 30 tables' },
    ],
  },
  {
    name: 'UI/UX Design',
    description: 'User-friendly and visually appealing interface design.',
    options: [
      { type: 'Basic', price: 1500.00, details: 'Simple wireframes and mockups' },
      { type: 'Standard', price: 2500.00, details: 'Full design with user flow' },
      { type: 'Premium', price: 8000.00, details: 'Comprehensive UX research and UI design' },
    ],
  },
  {
    name: 'Cloud Services Setup',
    description: 'Setup and configuration of cloud-based infrastructure.',
    options: [
      { type: 'Basic', price: 2000.00, details: 'Single service setup (e.g., AWS EC2)' },
      { type: 'Standard', price: 5000.00, details: 'Multiple services integration' },
      { type: 'Enterprise', price: 10000.00, details: 'Complex cloud architecture' },
    ],
  },
  {
    name: 'Cybersecurity Audit',
    description: 'Comprehensive security assessment of your digital assets.',
    options: [
      { type: 'Basic', price: 3000.00, details: 'Vulnerability scanning' },
      { type: 'Standard', price: 7000.00, details: 'Penetration testing' },
      { type: 'Premium', price: 15000.00, details: 'Full security audit and consultation' },
    ],
  },
  {
    name: 'API Development',
    description: 'Custom API creation for system integrations and data exchange.',
    options: [
      { type: 'Basic', price: 5000.00, details: 'Simple REST API' },
      { type: 'Advanced', price: 10000.00, details: 'Complex API with authentication' },
    ],
  },
  {
    name: 'Maintenance and Support',
    description: 'Ongoing technical support and system maintenance.',
    options: [
      { type: 'Basic', price: 500.00, details: 'Monthly, 5 hours support' },
      { type: 'Standard', price: 1000.00, details: 'Monthly, 10 hours support' },
      { type: 'Premium', price: 2000.00, details: 'Monthly, 24/7 support' },
    ],
  }
];

const QuotationPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    selectedServices: [],
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const [quotation, setQuotation] = useState('');
  const [error, setError] = useState('');
  const [selectedService, setSelectedService] = useState('');

  useEffect(() => {
    calculateTotalAmount();
  }, [formData.selectedServices]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleServiceSelection = (e) => {
    setSelectedService(e.target.value);
  };

  const handleOptionSelection = (e) => {
    const [serviceName, optionType] = e.target.value.split('|');
    const updatedServices = formData.selectedServices.filter(
      service => service.name !== serviceName
    );
    if (optionType !== 'none') {
      updatedServices.push({ name: serviceName, optionType, quantity: 1 });
    }
    setFormData({ ...formData, selectedServices: updatedServices });
  };

  const handleQuantityChange = (serviceName, quantity) => {
    const updatedServices = formData.selectedServices.map(service => 
      service.name === serviceName ? { ...service, quantity: parseInt(quantity) } : service
    );
    setFormData({ ...formData, selectedServices: updatedServices });
  };

  const calculateTotalAmount = () => {
    const total = formData.selectedServices.reduce((sum, service) => {
      const selectedService = serviceOptions.find(option => option.name === service.name);
      if (selectedService) {
        const selectedOption = selectedService.options.find(option => option.type === service.optionType);
        return sum + (selectedOption ? selectedOption.price * service.quantity : 0);
      }
      return sum;
    }, 0);
    setTotalAmount(total);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.selectedServices.length === 0) {
      setError('Please select at least one service before generating a quote.');
      return;
    }
    setError('');
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
  
    const quotationContent = `
      <div id="quotation-section" style="font-family: Arial, sans-serif; padding: 20px; border: 1px solid #ddd; border-radius: 10px; max-width: 800px; margin: auto; position: relative;">
        <style>
          @media only screen and (max-width: 600px) {
            #quotation-section {
              padding: 10px;
            }
            .logo {
              height: 80px !important;
              top: 10px !important;
              right: 10px !important;
            }
            .quote-title {
              margin-top: 100px !important;
            }
            table {
              font-size: 14px;
            }
            td, th {
              padding: 6px 4px !important;
            }
          }
        </style>
        <img src="images/logo.png" alt="logo" class="logo" style="position: absolute; top: 20px; right: 20px; height: 100px;" />
        <h2 class="quote-title" style="text-align: center; margin-top: 50px; color: black;">Quotation</h2>
        
        <div style="margin-bottom: 30px;">
          <h3 style="color: black;">Company Information</h3>
          <p><strong style="color: black;">Company:</strong> FessyNam Tech Solutions</p>
          <p><strong style="color: black;">Email:</strong> info@fessynam.com</p>
          <p><strong style="color: black;">Website:</strong> www.fessynam.com</p>
          <p><strong style="color: black;">Mobile:</strong> +264 81 834 7176</p>
          <p><strong style="color: black;">Location:</strong> Windhoek, Namibia</p>
          <p><strong style="color: black;">Date:</strong> ${currentDate}</p>
          <p><strong style="color: black;">Time:</strong> ${currentTime}</p>
        </div>
        
        <div style="margin-bottom: 30px;">
          <h3 style="color: black;">Client Details</h3>
          <p><strong style="color: black;">Name:</strong> ${formData.name}</p>
          <p><strong style="color: black;">Email:</strong> ${formData.email}</p>
          <p><strong style="color: black;">Phone:</strong> ${formData.phone}</p>
        </div>
        
        <div style="margin-bottom: 30px; overflow-x: auto;">
          <h3 style="color: black;">Selected Services</h3>
          <table style="width: 100%; border-collapse: collapse; min-width: 600px;">
            <tr style="border-bottom: 1px solid #ddd;">
              <th style="text-align: left; padding: 8px; color: black;">Product Name</th>
              <th style="text-align: left; padding: 8px; color: black;">Description</th>
              <th style="text-align: right; padding: 8px; color: black;">Quantity</th>
              <th style="text-align: right; padding: 8px; color: black;">Unit Price</th>
              <th style="text-align: right; padding: 8px; color: black;">Total</th>
            </tr>
            ${formData.selectedServices.map(service => {
              const selectedService = serviceOptions.find(option => option.name === service.name);
              const selectedOption = selectedService.options.find(option => option.type === service.optionType);
              const total = selectedOption.price * service.quantity;
              return `
                <tr style="border-bottom: 1px solid #ddd;">
                  <td style="padding: 8px; color: black;">${service.name}</td>
                  <td style="padding: 8px; color: black;">${selectedOption.details}</td>
                  <td style="text-align: right; padding: 8px; color: black;">${service.quantity}</td>
                  <td style="text-align: right; padding: 8px; color: black;">${selectedOption.price.toFixed(2)} NAD</td>
                  <td style="text-align: right; padding: 8px; color: black;">${total.toFixed(2)} NAD</td>
                </tr>
              `;
            }).join('')}
          </table>
        </div>
        
        <div style="margin-bottom: 30px; text-align: right;">
          <p><strong style="color: black;">Subtotal:</strong> ${totalAmount.toFixed(2)} NAD</p>
          <p><strong style="color: black;">VAT (0%):</strong> 0.00 NAD</p>
          <p><strong style="color: black;">Total Amount:</strong> ${totalAmount.toFixed(2)} NAD</p>
        </div>
  
        <div style="margin-top: 50px; text-align: center;">
          <p style="color: black;">Thank you for considering our services. We look forward to working with you.</p>
        </div>
      </div>
    `;
  
    setQuotation(quotationContent);
  };

  const handleDownload = () => {
    if (!quotation) {
      setError('Please generate a quote before downloading.');
      return;
    }
    setError('');
    const quotationElement = document.querySelector("#quotation-section");
    if (!quotationElement) {
      setError('Unable to find quotation content. Please try generating the quote again.');
      return;
    }
    html2canvas(quotationElement).then(canvas => {
      try {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save('quotation.pdf');
      } catch (error) {
        console.error('Error generating PDF:', error);
        setError('An error occurred while generating the PDF. Please try again.');
      }
    }).catch(error => {
      console.error('Error capturing quotation content:', error);
      setError('An error occurred while preparing the quotation. Please try again.');
    });
  };

  const handleNewQuote = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      selectedServices: [],
    });
    setTotalAmount(0);
    setQuotation('');
    setError('');
    setSelectedService('');
  };

  return (
    <div id="quote" className="quotation-container"> {/* Add id="quote" here */}
      <div className="quotation-card">
        <h2 className="quote-title">Get Quote</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
  
          <h3>Select Services</h3>
          <div className="form-group">
            <select onChange={handleServiceSelection} value={selectedService}>
              <option value="">Select a service</option>
              {serviceOptions.map((service) => (
                <option key={service.name} value={service.name}>
                  {service.name}
                </option>
              ))}
            </select>
          </div>
  
          {selectedService && (
            <div className="service-option">
              <h4>{selectedService}</h4>
              <p>{serviceOptions.find((s) => s.name === selectedService).description}</p>
              <select
                onChange={handleOptionSelection}
                value={
                  formData.selectedServices.find((s) => s.name === selectedService)
                    ? `${selectedService}|${formData.selectedServices.find((s) => s.name === selectedService).optionType}`
                    : `${selectedService}|none`
                }
              >
                <option value={`${selectedService}|none`}>Select option</option>
                {serviceOptions.find((s) => s.name === selectedService).options.map((option) => (
                  <option key={option.type} value={`${selectedService}|${option.type}`}>
                    {option.type} - {option.price} NAD {option.details && `(${option.details})`}
                  </option>
                ))}
              </select>
              {formData.selectedServices.find((s) => s.name === selectedService) && (
                <div className="quantity-selector">
                  <label htmlFor={`quantity-${selectedService}`}>Quantity:</label>
                  <input
                    type="number"
                    id={`quantity-${selectedService}`}
                    min="1"
                    value={formData.selectedServices.find((s) => s.name === selectedService).quantity}
                    onChange={(e) => handleQuantityChange(selectedService, e.target.value)}
                  />
                </div>
              )}
            </div>
          )}
  
          <div className="total-amount">
            <p>Total: {totalAmount.toFixed(2)} NAD</p>
          </div>
  
          <div className="form-actions">
            <button type="submit">Generate Quote</button>
            <button type="button" onClick={handleDownload}>Download Quote</button>
            <button type="button" onClick={handleNewQuote}>New Quote</button>
          </div>
        </form>
  
        {quotation && <div className="quotation-preview" dangerouslySetInnerHTML={{ __html: quotation }} />}
      </div>
    </div>
  );
}  

export default QuotationPage;