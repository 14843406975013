import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  return (
    <section id="about">
      <h2 className="section-title">About FessyNam</h2>
      <div className="about-box">
        <FontAwesomeIcon icon={faInfoCircle} size="3x" />
        <div className="about-text">
          <p>FessyNam Tech Solutions is dedicated to providing innovative and reliable technology solutions to meet the needs of businesses and individuals. With a focus on quality and customer satisfaction, we offer a range of services from software development to IT consulting. Our team of experts works closely with clients to deliver tailored solutions that drive success and efficiency.</p>
        </div>
      </div>
    </section>
  );
};

export default About;