import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('https://fessynam.com/backend/contact.php', formData)  // Replace localhost with your live domain
      .then(response => {
        if (response && response.data && response.data.message) {
          console.log(response.data.message);
          alert('Message sent successfully!');
          // Reset form after success
          setFormData({ name: '', email: '', message: '' });
        } else {
          console.log('Unexpected response:', response);
          alert('Failed to send message.');
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          console.error('Error response:', error.response.data.error);
          alert('Failed to send message: ' + error.response.data.error);
        } else {
          console.error('Error:', error);
          alert('Failed to send message. Please try again later.');
        }
      });
  };

  return (
    <section id="contact">
      <h2 className="section-title">Contact Us</h2>
      <form id="contact-form" onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter your name"
          required
        />
        
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email address"
          required
        />
        
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Your message here"
          rows="4"
          required
        ></textarea>
        
        <button type="submit">Send Message</button>
      </form>
    </section>
  );
};

export default ContactForm;
