// Header.jsx
import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white shadow-md">
      <div className="header-container">
        <div className="logo-container">
          <a href="#home">
            <img src="/images/logo.png" alt="FessyNam Logo" className="h-8" />
          </a>
        </div>
        <nav>
          <ul className={`flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 p-4 md:p-0 ${isMenuOpen ? 'open' : ''}`}>
            <li>
              <a
                href="#home"
                onClick={() => setIsMenuOpen(false)}
                className="text-gray-800 hover:text-black transition duration-300"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#about"
                onClick={() => setIsMenuOpen(false)}
                className="text-gray-800 hover:text-black transition duration-300"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#team"
                onClick={() => setIsMenuOpen(false)}
                className="text-gray-800 hover:text-black transition duration-300"
              >
                Team
              </a>
            </li>
            <li>
              <a
                href="#services"
                onClick={() => setIsMenuOpen(false)}
                className="text-gray-800 hover:text-black transition duration-300"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#portfolio"
                onClick={() => setIsMenuOpen(false)}
                className="text-gray-800 hover:text-black transition duration-300"
              >
                Portfolio
              </a>
            </li>
            <li>
              <a
                href="#contact"
                onClick={() => setIsMenuOpen(false)}
                className="text-gray-800 hover:text-black transition duration-300"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
        <div className="get-started-container">
  <a href="#quote" className="get-started-btn">
    Get Quote
  </a>
</div>

        <button
          className="hamburger-menu md:hidden text-gray-800 focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
    </header>
  );
};

export default Header;
