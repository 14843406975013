import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faCheckCircle, faChartLine, faShieldAlt, faLightbulb, faTools, faBullhorn, faShoppingCart, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faSearch, faCode, faPaintBrush } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  const services = [
    { icon: faServer, title: "Web Hosting", description: "Reliable and secure web hosting services to keep your site up and running smoothly." },
    { icon: faCheckCircle, title: "Software Verification & Validation", description: "Ensuring software reliability, functionality, and compliance with industry standards." },
    { icon: faChartLine, title: "Data Analytics", description: "Business intelligence, data visualization, and predictive analytics." },
    { icon: faShieldAlt, title: "Cybersecurity", description: "Security audits, threat analysis, and protection solutions." },
    { icon: faLightbulb, title: "Consulting Services", description: "IT strategy, digital transformation, and technology advisory." },
    { icon: faTools, title: "Maintenance & Support", description: "Ongoing support, bug fixes, and software updates." },
    { icon: faBullhorn, title: "Digital Marketing", description: "Content marketing, social media management, and email marketing." },
    { icon: faShoppingCart, title: "E-commerce Solutions", description: "Online store setup, payment gateway integration, and e-commerce strategy." },
    { icon: faDesktop, title: "Operating System Setup & Optimization", description: "Setup, configuration, and optimization of operating systems for optimal performance." },
    { icon: faSearch, title: "SEO Services", description: "Comprehensive SEO strategies to enhance your website's visibility and ranking on search engines." },
    { icon: faCode, title: "API Development", description: "Design and develop robust APIs to facilitate seamless integration and functionality across your applications." },
    { icon: faPaintBrush, title: "UI/UX Design", description: "Create intuitive and engaging user interfaces with exceptional user experiences to drive user satisfaction and engagement."}
  ];

  return (
    <section id="services">
      <h2>Our Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <FontAwesomeIcon icon={service.icon} size="3x" />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;