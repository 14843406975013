import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Team from './components/Team'; // Importing Team Section
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import QuotationPage from './components/QuotationPage'; // Importing Quotation Page
import ContactForm from './components/ContactForm';
// import TechPartners from './components/TechPartners'; // Importing Tech Partners Section
import Footer from './components/Footer';
import './styles/main.css';

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="FessyNam Tech Solutions offers web development, mobile apps, cloud services, and IT consulting in Namibia." />
        <meta name="keywords" content="Web Development, Mobile Apps, Cloud Services, IT Consulting, Namibia, Tech Solutions" />
        <meta name="author" content="Festus Alpheus" />
        <title>FessyNam Tech Solutions | Web Development & IT Consulting in Namibia</title>
        <link rel="canonical" href="https://www.fessynam.com/" />
        <meta property="og:title" content="FessyNam Tech Solutions" />
        <meta property="og:description" content="FessyNam Tech Solutions provides innovative tech solutions including web development, mobile apps, and IT consulting in Namibia." />
        <meta property="og:image" content="https://www.fessynam.com/images/og-image.jpg" />
        <meta property="og:url" content="https://www.fessynam.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FessyNam Tech Solutions" />
        <meta name="twitter:description" content="FessyNam Tech Solutions provides innovative tech solutions including web development, mobile apps, and IT consulting in Namibia." />
        <meta name="twitter:image" content="https://www.fessynam.com/images/twitter-image.jpg" />
      </Helmet>

      <Header />
      <main>
        <Hero />
        <About />
        <Team /> {/* Added Meet the Team Section */}
        <Services />
        <Portfolio />
        <QuotationPage /> {/* Added Quotation Page */}
        <ContactForm />
        {/* <TechPartners /> Added Tech Partners Section */}
      </main>
      <Footer />
    </>
  );
}

export default App;
