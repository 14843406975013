import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop, faShoppingCart, faGlobe, faCode, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

const Portfolio = () => {
  const portfolioItems = [
    { icon: faLaptop, title: "Health App", description: "A comprehensive health and wellness app focused on diet and nutrition..." },
    { icon: faShoppingCart, title: "Buy & Sell App", description: "An intuitive and user-friendly platform designed for buying and selling items with ease..." },
    { icon: faGlobe, title: "www.abiref.com", description: "A modern and user-friendly website showcasing electrical services and products..." },
    { icon: faCode, title: "Hackathon", description: "Innovative projects developed during hackathons, focusing on creative problem-solving and new technologies..." },
    { icon: faCalendarDay, title: "Notes Taking App", description: "A simple app for organizing and managing notes with ease..." },
    { icon: faGlobe, title: "Cubango Fresh Produce", description: "A modern and user-friendly website showcasing farm products and exports..." },

  ];

  return (
    <>
      <div className="portfolio-filters">
        <button className="filter-btn" data-filter="all">"Coding With Passion"</button>
      </div>

      <section id="portfolio" className="portfolio-section">
        <h2>Our Portfolio</h2>
        <div className="portfolio-grid">
          {portfolioItems.map((item, index) => (
            <div key={index} className="portfolio-card">
              <FontAwesomeIcon icon={item.icon} size="3x" />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Portfolio;