import React from 'react';

const Hero = () => {
  const images = [
    { src: "/images/support1.webp", alt: "support" },
    { src: "/images/digital-market.webp", alt: "digital-market" },
    { src: "/images/ecom.jpg", alt: "ecom" },
    { src: "/images/cyber1.jpg", alt: "cyber1" },
    { src: "/images/q2.jpg", alt: "q2" },
    { src: "/images/thankyou.jpg", alt: "thankyou" },
    { src: "/images/testing.jpg", alt: "testing" },
    { src: "/images/web1.jpg", alt: "web1" },
    { src: "/images/AI.jpg", alt: "AI" },
    { src: "/images/qoutes.jpg", alt: "qoutes" }
  ];

  return (
    <section id="home" className="hero">
      <div className="banner">
        <div className="slider" style={{"--quantity": images.length}}>
          {images.map((img, index) => (
            <div key={index} className="item" style={{"--position": index + 1}}>
              <img src={img.src} alt={img.alt} />
            </div>
          ))}
        </div>
        <div className="content">
          <h1 data-content="FessyNam">FessyNam</h1>
          <div className="tagline">
            {/* Tagline content */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;