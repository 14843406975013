import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-section footer-contact">
          <h3>Contact Us</h3>
          <p>Phone: <a href="tel:+264818347176">+264 81 834 7176</a></p>
          <p>Email: <a href="mailto:info@fessynam.com">info@fessynam.com</a></p>
        </div>

        <div className="footer-section footer-location">
          <h3>Location</h3>
          <p>Namibia, Windhoek</p>
          <p>Windhoek West, Wecke Street 11</p>
          <li><a href="https://g.page/r/CW3st5icw3nnEAI/review" target="_blank" rel="noopener noreferrer">Locate Us</a></li>
        </div>

        <div className="footer-section footer-navigation">
          <h3>Navigation</h3>
          <ul className="footer-nav">
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#portfolio">Portfolio</a></li>
            <li><a href="#contact">Contact</a></li>
                                </ul>
        </div>

        <div className="footer-section footer-social">
          <h3>Follow Us</h3>
          <ul className="social-links">
            <li><a href="https://wa.me/264818347176" target="_blank" rel="noopener noreferrer">WhatsApp</a></li>
            <li><a href="https://www.linkedin.com/company/fessynam-tech-solutions" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://www.facebook.com/fessynam" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li><a href="https://www.instagram.com/fessynam" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            <li><a href="https://github.com/Fessynam" target="_blank" rel="noopener noreferrer">GitHub</a></li>
            <li><a href="https://g.page/r/CW3st5icw3nnEAI/review" target="_blank" rel="noopener noreferrer">Review Us</a></li>


          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 FessyNam Tech Solutions. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;